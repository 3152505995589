<template>
  <div class="home" v-if="hasPerm('home.view_structure')">
    <page-header title="Administration" icon="fa fa-cogs"></page-header>
    <ul class="app-menu">
      <li v-if="hasPerm('home.change_structure')">
        <router-link :to="{ name: 'entity-own', }">
          <i class="fas fa-id-badge"></i> Données de votre structure
        </router-link>
      </li>
      <li v-if="hasPerm('adhesions')">
        <router-link :to="{ name: 'adhesion-types-list', }">
          <i class="fas fa-id-card"></i> Configurer les cartes d'adhésion
        </router-link>
      </li>
      <li v-if="hasAllPerms(['auth.view_user', 'auth.view_group'])">
        <router-link :to="{ name: 'users-list', }">
          <i class="fa fa-users-cog"></i> Configurer les utilisateurs
        </router-link>
      </li>
      <li v-if="hasAllPerms(['activities.add_coreactivity', 'activities.view_coreactivityauthorization'])">
        <router-link :to="{ name: 'activities-authorizations', }">
          <i class="fas fa-chess-knight"></i> Autorisations pour les activités
        </router-link>
      </li>
      <li v-if="hasAllPerms(['youth.add_youthhomeactivity', 'youth.view_youthhomeactivityauthorization'])">
        <router-link :to="{ name: 'youth-home-authorizations', }">
          <i :class="youthHomeIcon"></i> Autorisations pour {{youthHomeLabel}}
        </router-link>
      </li>
      <li v-if="hasPerm('payments.view_analyticaccount')">
        <router-link :to="{ name: 'analytic-accounts', }">
          <i class="fas fa-table"></i> Comptes analytiques
        </router-link>
      </li>
      <li v-if="hasPerm('payments.view_generalaccount')">
        <router-link :to="{ name: 'general-accounts', }">
          <i class="fas fa-table"></i> Comptes généraux
        </router-link>
      </li>
      <li v-if="hasPerm('families.change_family')">
        <router-link :to="{ name: 'SchoolYearsList', }">
          <i class="fa fa-calendar"></i> Saisons
        </router-link>
      </li>
      <li v-if="hasPerm('schools.view_school')">
        <router-link :to="{ name: 'schools-list', }">
          <i class="fa fa-school"></i> Écoles
        </router-link>
      </li>
      <li v-if="hasPerm('schools.view_schoollevel')">
        <router-link :to="{ name: 'school-levels-list', }">
          <i class="fa fa-school"></i> Niveaux scolaires
        </router-link>
      </li>
      <li v-if="hasPerm('youth.view_seanceperiod')">
        <router-link :to="{ name: 'seance-periods-list', }">
          <i class="fas fa-list"></i> Liste des périodes
        </router-link>
      </li>
      <li v-if="hasPerm('youth.view_seancetype')">
        <router-link :to="{ name: 'seance-types-list', }">
          <i class="fas fa-list"></i> Liste des types de séances
        </router-link>
      </li>
      <li v-if="hasPerm('people.view_city')">
        <router-link :to="{ name: 'streets-list', }">
          <i class="fas fa-house-chimney"></i> Liste des villes, quartiers et rues
        </router-link>
      </li>
      <li v-if="isStaff">
        <a :href="getAdminLink()"><i class="fab fa-python"></i> Administration BDD</a>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src

import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'

export default {
  name: 'admin-home',
  components: {
    PageHeader,
  },
  mixins: [BackendMixin],
  data() {
    return {
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    isStaff() {
      return store.getters.isStaff
    },
  },
  methods: {
  },
}
</script>

<style lang="less">
</style>
